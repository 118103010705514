import './bootstrap'
import '../css/app.css'

import { createApp, h } from 'vue'
import { createInertiaApp } from '@inertiajs/inertia-vue3'
import { InertiaProgress } from '@inertiajs/progress'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m'
import {
    ModuleRegistry,
    AllCommunityModule
} from 'ag-grid-community'
import {
    AllEnterpriseModule,
    LicenseManager
} from 'ag-grid-enterprise'



/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
import Vueform from '@vueform/vueform'
import vueformConfig from '../../vueform.config'

/* import font awesome icon component */
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import {
    faUpload,
    faTablePivot,
    faUser,
    faRightFromBracket, //logout
    faUserSecret,
    faDollarSign,
    faGear,
    faUsers,
    faClockThree,
    faBullhorn,
    faWrench,
    faFiles,
    faListCheck,
    faPlaneUp,
    faFileContract,
    faBook,
    faBooks,
    faLifeRing,
    faHouse,
    faUserPlus,
    faClockRotateLeft,
    faPaperclip,
    faCloudArrowUp,
    faCloudArrowDown,
    faLink,
    faPlus,
    faWarning,
    faLinkSlash,
    faPenToSquare,
    faTableCells,
    faTableList,
    faEllipsisVertical,
    faPen as farPen,
    faCircle as farCircle,
    faCirclePlus as farCirclePlus,
    faTimes as farTimes,
    faTrash as farTrash,
    faFileUpload as farFileUpload,
    faLink as farLink,
    faEdit as farEdit,
    faBell as farBell,
    faTriangleExclamation as farTriangleExclamation,
    faFileChartPie,
    faBan,
    faPlaneSlash,
    faBarChart as farBarChart,
} from '@fortawesome/pro-regular-svg-icons'
import {
    faCirclePlus as fasCirclePlus,
    faPen as fasPen,
    faX as fasX,
    faTimes as fasTimes,
    faCircleCheck as fasCircleCheck,
    faCircle as fasCircle,
    faSpinner as fasSpinner,
    faExclamationCircle as fasExclamationCircle,
    faCircleDashed as fasCircleDashed,
    faMagnifyingGlass as fasMagnifyingGlass,
    faMoon as fasMoon,
    faLightbulb as fasLightbulb,
    faChevronDown as fasChevronDown,
    faCircleXmark as fasCircleXmark,
    faTrash as fasTrash,
    faLinkSlash as fasLinkSlash,
} from '@fortawesome/pro-solid-svg-icons'
import { faSlack } from '@fortawesome/free-brands-svg-icons'

import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

ModuleRegistry.registerModules([
    AllCommunityModule,
    AllEnterpriseModule
])

LicenseManager.setLicenseKey("Using_this_{AG_Grid}_Enterprise_key_{AG-076733}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{PLANELOGIX,_Inc.}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Planelogix}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Planelogix}_need_to_be_licensed___{Planelogix}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{10_March_2026}____[v3]_[01]_MTc3MzEwMDgwMDAwMA==bfdc53d08dcb2c6f54c5044f73503c7c");


/* add icons to the library */
library.add(
    //Regular
    farTriangleExclamation,
    farBarChart,
    farLink,
    farEdit,
    farBell,
    faUpload,
    farTimes,
    farTrash,
    farFileUpload,
    farPen,
    farCircle,
    farCirclePlus,
    faTablePivot,
    faUser,
    faRightFromBracket, //logout
    faUserSecret,
    faDollarSign,
    faGear,
    faUsers,
    faClockThree,
    faBullhorn,
    faFiles,
    faListCheck,
    faPlaneUp,
    faFileContract,
    faBook,
    faBooks,
    faLifeRing,
    faHouse,
    faUserPlus,
    faClockRotateLeft,
    faPaperclip,
    faCloudArrowUp,
    faCloudArrowDown,
    faLink,
    faPlus,

    faWarning,
    faLinkSlash,
    faPenToSquare,
    faTableCells,
    faTableList,
    faEllipsisVertical,
    faWrench,
    faFileChartPie,
    faBan,
    faPlaneSlash,
    //Solid
    fasLightbulb,
    fasCircleDashed,
    fasMoon,
    fasExclamationCircle,
    fasSpinner,
    fasCircle,
    fasCirclePlus,
    fasPen,
    fasTimes,
    fasCircleCheck,
    fasMagnifyingGlass,
    fasX,
    fasChevronDown,
    fasCircleXmark,
    fasTrash,
    fasLinkSlash,
    //Brands
    faSlack
)

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'PLANELOGIX'

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, app, props, plugin }) {
        // 1) Create the base Vue app
        const myApp = createApp({ render: () => h(app, props) })
            .use(plugin)
            .use(ZiggyVue)
            .use(Vueform, vueformConfig)
            .component("font-awesome-icon", FontAwesomeIcon)
            .component("font-awesome-layers", FontAwesomeLayers);

        // 2) Only initialize Bugsnag in production
        if (import.meta.env.PROD) {
            Bugsnag.start({
                apiKey: "a31a640578098c10cfb6af79ffb8704d",
                plugins: [new BugsnagPluginVue()],
                appVersion: props?.initialPage?.props?.appVersion,
                releaseStage: props?.initialPage?.props?.releaseStage,
                user: {
                    id: props.initialPage?.props?.user?.id,
                    name: props.initialPage?.props?.user?.FullName,
                    email: props.initialPage?.props?.user?.email,
                },
                metadata: {
                    current_team: {
                        id: props.initialPage?.props?.user?.current_team?.id,
                        tailNumber: props.initialPage?.props?.user?.current_team?.name,
                    },
                },
            });

            // Get the Vue plugin from Bugsnag and use it
            const bugsnagVue = Bugsnag.getPlugin("vue");
            myApp.use(bugsnagVue);
        }

        // 3) Mount the app
        myApp.mount(el);
    },
})

InertiaProgress.init({
    color: '#8cbcff', //#4B5563 works better in dark and light mode
})
